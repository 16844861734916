.frontpage {
    padding-top: 120px;

    .hyperlink {
        background-color: red;
        .flex-container {
            display: flex;
        }

        .flex-container > div {
            font-size: 18px;
            width: calc(100% - 20px);
            padding-bottom: 10px;
            padding-top: 10px;

            &:last-child {
                text-align: right;
                width: 20px;
                align-items: center;
                display: flex;
            }

            .correct {
                color: green;
            }

            .wrong,
            .correct {
                font-size: 22px;
            }

            .subject {
                font-weight: 700;

                @media only screen and (max-width: 460px) {
                    font-size: 16px;
                }
            }

            i {
                font-size: 14px;

                @media only screen and (max-width: 460px) {
                    font-size: 12px;
                }
            }
        }
    }

    .done {
        cursor: default;
    }

    .waiting {
        .flex-container {
            &:hover {
                background-color: #f1f1f1 !important;
            }
        }
    }
}
