.loading {
    padding-top: 120px;
}

.details {
    padding-top: 120px;

    h1 {
        font-size: 24px;

        .back {
            margin-right: 15px;
            position: relative;
            top: 2px;
        }

        @media only screen and (max-width: 460px) {
            font-size: 20px;
        }
    }

    input {
        margin-right: 15px;
        // margin-top: 0;
    }

    button {
        background-color: #001a39;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 16px;
        margin-top: 20px;
        transition: 0.3s;

        &:hover {
            background-color: #3064a3;
        }
    }

    .Toastify__toast-container {
        width: 450px;

        .Toastify__toast-body {
            color: black;
        }

        .Toastify__close-button {
            align-self: auto;
            padding: 0;
            background-color: transparent;
            margin-top: 0;
            color: black;
        }

        @media only screen and (max-width: 480px) {
            width: calc(100% - 40px);
            left: 20px;
            right: 20px;
            bottom: 15px;
        }
    }
}
