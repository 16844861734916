@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a{
    text-decoration: none;
    color: black;

    &:hover,&:visited{
      color: black;
    }
  }

  .container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1240px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .container-xs{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}