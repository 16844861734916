header {
    background-color: white;
    height: 90px;
    position: fixed;
    width: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    z-index: 1;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        gap: 30px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            gap: 30px;
            align-items: center;

            li {
                a {
                    text-decoration: none;

                    img {
                        height: 65px;
                        width: 65px;

                        @media only screen and (max-width: 460px) {
                            width: 45px;
                            height: 45px;
                        }
                    }
                }

                .desktop__title {
                    margin: 0;
                    font-size: 16px;

                    @media only screen and (max-width: 830px) {
                        display: none;
                    }

                    // @media only screen and (max-width: 460px) {
                    //     font-size: 22px;
                    
                    // }
                }

                .mobile__title{
                    display: none;

                    @media only screen and (max-width: 830px) {
                        display: block;
                    }
                }
            }

            @media only screen and (max-width: 460px) {
                gap: 10px;
            }
        }

        .user {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            -webkit-user-select: none;

            svg {
                margin-left: 10px;
            }

            @media only screen and (max-width: 460px) {
                font-size: 13px;
            }
        }
    }

    .dropdown-menu {
        margin-top: 10px;
        padding: 12px 0px;
        border-radius: 6px;
        background-color: white;
        float: right;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        transition: 0.3s;

        a {
            padding: 5px 15px;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        h1 {
            // margin: 0;
        }
    }

    .active {
        display: block;
    }

    .inactive {
        display: none;
    }
}
