.footer {
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: #001a39;
    color: #909da4;
    font-size: 15px;
    

    @media only screen and (max-width: 854px) {
        height: 35px;
    }

    @media only screen and (max-width: 448px) {
        font-size: 13px;
    }

    @media only screen and (max-width: 394px) {
        height: 42px;
    }
}
