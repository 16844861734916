.page-404{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 110px);
    margin-left: auto;
    margin-right: auto;

    .nopage{
        text-align: center;

        h1{
            font-size: 32px;
            margin-top: 75px;
            margin-bottom: 25px;
        }

        p{
            margin-top: 25px;
            margin-bottom: 50px;
        }

        a{
            padding: 14px 16px;
            background: #001a39;
            border-radius: 10px;
            color: white;
            transition: .3s;

            &:hover{
                background-color: #3064a3;
            }
        }
    }
}