body{
    background-color: #e8e8e8;

    .page {
        width: 100%;
        height: calc(100vh - 20px);

        > .container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            height: calc(100vh - 70px);

            // @media (max-width: 520px) {
            //     align-items: unset;
            //     min-height: 0;
            // }

            .login {
                margin-top: 0;
                border-radius: 8px;
                width: 370px;
                padding: 40px 60px;
                background-color: #fff;
                font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                border-radius: 5px;
                padding: 40px 50px;
                padding-bottom: 20px;
                box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
                border: 1px solid #ddd;

                @media (max-width: 520px) {
                    padding: 40px 30px;
                    width: 100%;
                    margin-top: 0;
                }

                img {
                    height: 60px;
                    margin-bottom: 24px;

                    @media only screen and (max-width: 460px) {
                        height: 45px;
                    }
                }

                h1 {
                    letter-spacing: -1px;
                    color: #3058a4;
                    margin: 0;
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 0px;

                    @media only screen and (max-width: 460px) {
                        font-size: 20px;
                    }
                }

                p {
                    @media only screen and (max-width: 460px) {
                        font-size: 14px;
                    }
                }

                .sign-in {
                    font-weight: 700;
                    padding-top: 32px;
                    margin: 0;
                }

                .panel {
                    padding-top: 26px;

                    .form-group {
                        input {
                            border-radius: 3px;
                            font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                            background-color: #eee;
                            border: none;
                            padding: 12px 15px;
                            margin: 8px 0;
                            width: 100%;
                            box-sizing: border-box;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                color: #b5b5b5;
                                font-size: 14px;
                                font-family: "Inter", "Open Sans", Arial, Helvetica, Verdana, sans-serif;
                            }
                        }

                        .validation-message {
                            min-height: 24px;
                            line-height: 24px;
                            color: #d50000;
                            text-align: left;
                            margin-bottom: -2px;
                        }
                    }

                    .button-container {
                        text-align: center;

                        button {
                            width: 100%;
                            margin-top: 10px;
                            margin-bottom: 30px;
                            border-radius: 5px;
                            border: 1px solid #3058a4;
                            background-color: #3058a4;
                            color: #ffffff;
                            font-size: 13px;
                            letter-spacing: 1px;
                            transition: transform 80ms ease-in;
                            padding: 10px 25px;
                            cursor: pointer;
                            position: relative;
                            transition: 0.5s;
                            height: 38px;

                            &:hover {
                                color: #fff;
                                background-color: #173872;
                                border-color: #173872;
                                transition: 0.5s;
                            }

                            &.loading {
                                //padding-left: 50px;
                                background: #425a87;
                            }

                            .loader {
                                display: inline-block;
                                left: calc(50% - 10px);
                                width: 20px;
                                height: 20px;
                                border: 2px solid rgba(0, 0, 0, 0);
                                border-top: 2px solid #fff;
                                box-sizing: border-box;
                                border-radius: 50%;
                                animation: loader-spin 2s linear infinite;
                                position: absolute;
                                top: 8px;
                            }
                            @keyframes loader-spin {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }
                        }

                        .disabled {
                            background-color: #092a68;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .Toastify__toast-container {
            width: 450px;

            .Toastify__toast-body {
                color: black;
            }

            .Toastify__close-button {
                align-self: auto;
            }

            @media only screen and (max-width: 480px) {
                width: calc(100% - 40px);
                left: 20px;
                right: 20px;
                bottom: 15px;
            }
        }
    }

    .footer{
        margin-top: 0;
        height: 20px;
        text-align: left;

        .container{
            margin-bottom: 0;

            .copy{
                text-align: left;
                display: inline-block;
            }

            .menu{
                float: right;
                display: inline-block;

                a,
                a:visited,
                a:hover {                    
                    color: #909da4;
                    text-decoration: underline;
                }
            }
        }
    }
}